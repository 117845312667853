/* General */
$fontSize:10px;
$borderRadiusL:16px;
$borderRadiusM:12px;
$borderRadiusS:8px;
$transitionDuration:.3s;

//$shadowOverlay:rgba(145, 158, 171, 0.2) 0px 5px 5px -3px, rgba(145, 158, 171, 0.14) 0px 8px 10px 1px, rgba(145, 158, 171, 0.12) 0px 3px 14px 2px;
$shadowOverlay:rgba(var(--shadow-color), 0.24) 0px 0px 2px 0px, rgba(var(--shadow-color), 0.24) -20px 20px 40px -4px;

//$shadowCard:rgba(145, 158, 171, 0.2) 0px 3px 1px -2px, rgba(145, 158, 171, 0.14) 0px 2px 2px 0px, rgba(145, 158, 171, 0.12) 0px 1px 5px 0px !important;
$shadowCard:rgba(var(--shadow-color), 0.2) 0px 0px 2px 0px, rgba(var(--shadow-color), 0.12) 0px 12px 24px -4px;
$shadowContent: rgba(var(--primary-rgb), 24%) 0px 8px 16px 0px;
