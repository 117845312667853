.card {
    background-color: var(--surface-card);
    padding: 1.5rem;
    margin-bottom: 1rem;
    border-radius: $borderRadiusL;
    //box-shadow: 0px 3px 5px rgba(0,0,0,.02), 0px 0px 2px rgba(0,0,0,.05), 0px 1px 4px rgba(0,0,0,.08) !important;
    box-shadow: $shadowCard;

    &.card-w-title {
        padding-bottom: 2rem;
    }

    &:last-of-type {
        margin-bottom: 0;
    }
}

.p-fieldset {
    border-radius: $borderRadiusL;
    box-shadow: $shadowCard;
    border: none;

    .p-fieldset-legend {
        border-radius: $borderRadiusL;
        box-shadow: $shadowCard;
        border: none;
    }
}

.grid {
    margin-bottom: -.5rem;
}

.p-divider .p-divider-content {
    background-color: transparent;
    .p-tag{
        padding: .35rem .85rem;
        border-radius: $borderRadiusL;
        box-shadow: $shadowContent;
    }
}

.p-overlaypanel, .p-menu.p-menu-overlay{
    //color: rgb(33, 43, 54);
    border-radius: $borderRadiusM;
    box-shadow: $shadowOverlay;

    .p-overlaypanel-content{
        padding: 0;

        .p-menu{
            border: none;
            background: none;
            width: auto;
        }
    }
}

.staff-area .p-fieldset-content{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .staff-area-item{
        background: var(--surface-b);
    }
}

.p-text-primary { color: var(--primary-color); }
.p-mi { padding: 0.75rem 1.25rem; }
.m-mi { margin: 0.75rem 1.25rem; }
.p-button { border-radius: $borderRadiusM; }
.p-inputtext{
    border-radius: $borderRadiusS;
}
.p-ripple { position: sticky; }
.p-carousel-content {
    .p-carousel-indicator .p-link {
        border-radius: 3px !important;
    }
}
