.layout-topbar {
    position: fixed;
    height: 5rem;
    z-index: 997;
    left: 0;
    top: 0;
    width: 100%;
    padding: 0 2rem;
    background-color: var(--surface-card);
    transition: left $transitionDuration;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 3px 5px rgba(0,0,0,.02), 0px 0px 2px rgba(0,0,0,.05), 0px 1px 4px rgba(0,0,0,.08);

    .layout-topbar-left{
        flex: .3;
        display: flex;
    }

    .p-menubar.p-component {
        background: var(--surface-b);
        border-radius: $borderRadiusS;
        border: none;

        .p-menubar-root-list{
            min-width: 140px;
        }

        .p-menuitem{
            margin-left: .2rem;
            margin-right: .2rem;
            a {
                border-radius: $borderRadiusS;
                box-shadow: none;

                &, .p-menuitem-icon {
                    color: var(--surface-700);
                    transition: background-color 0.25s, color 0.25s, box-shadow 0.25s;
                }

                &.active, &:hover:is(.active) {
                    box-shadow: rgba(0, 0, 0, 0.16) -2px 4px 6px 0px;
                    background-color: var(--surface-800);
                    &, .p-menuitem-icon{
                        color: var(--surface-100);
                    }
                }

                &:hover:not(.active){
                    &, .p-menuitem-icon{
                        color: var(--text-color);
                    }
                }
            }
        }
    }

    .layout-topbar-logo {
        display: flex;
        align-items: center;
        color: var(--surface-900);
        font-size: 1.5rem;
        font-weight: 500;
        //width: 300px;
        border-radius: 12px;

        img {
            height: 3rem;
            margin-right: .5rem;
        }

        &:focus {
            @include focused();
        }
    }

    .layout-topbar-button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: relative;
        color: var(--text-color-secondary);
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        cursor: pointer;
        transition: background-color $transitionDuration;

        &:hover {
            color: var(--text-color);
            background-color: var(--surface-hover);
        }

        &:focus {
            @include focused();
        }

        i {
            font-size: 1.5rem;
        }

        span {
            font-size: 1rem;
            display: none;
        }
    }

    .layout-menu-button {
        margin-left: 1rem;
    }

    .layout-topbar-menu-button {
        display: none;

        i {
            font-size: 1.25rem;
        }
    }

    .layout-topbar-menu {
        //margin: 0 0 0 auto;
        justify-content: end;
        flex: .3;
        padding: 0;
        list-style: none;
        display: flex;

        .layout-topbar-button {
            margin-left: 1rem;
        }

        .topbar-user-menu {
            top: 56px !important;
            .user-menu-info{
                .p-button.balance {
                    height: 2rem;
                    background: linear-gradient(to left, var(--green-200) 50%, var(--green-300) 50%);
                    background-size: 200% 100%;
                    background-position: right bottom;
                    transition: background-position, border-color 0.5s ease-out;
                    color: #000;
                    border-color: var(--green-300);

                    .value {
                        transition: margin 0.5s ease-out;
                        margin-right: 0;
                        white-space: nowrap;
                    }
                    .action{
                        transition: margin 0.5s ease-out;
                        margin-left: calc(-100% + 2.5rem);
                        white-space: nowrap;
                    }
                }
                .p-button.balance:hover {
                    background-position: left bottom;
                    border-color: var(--green-200);

                    .value { margin-right: calc(-100% + 2.5rem) }
                    .action{ margin-left: 0%; }
                    i { background-color: var(--green-200); }
                }
                .p-button.balance i {
                    width: 2.5rem;
                    z-index: 1;
                    background-color: var(--green-300);
                    transition: background-color 0.5s ease-out;
                }
                .p-button.balance:focus {
                    box-shadow: 0 0 0 1px var(--green-400);
                }
            }

            .p-menu {
                .p-menu-list {
                    .p-menuitem{
                        a {
                            border-radius: $borderRadiusL;
                            margin: 0 .75rem;
                            transition: background-color 0.15s;

                            &.active {
                                font-weight: 700;
                                color: var(--primary-color);

                                .p-menuitem-icon {
                                    color: var(--primary-color);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .dropdown-city{
        border: 0px none;
        background: transparent;

        .p-dropdown-label{
            padding: 0.25rem 0.25rem;
        }

        &:not(.p-disabled).p-focus{
            border: 0px none;
            box-shadow: none;
            span{
                color: var(--primary-color);
            }
        }

        &:not(.p-disabled):hover {
            span{
                color: var(--primary-color);
            }
        }

        .p-dropdown-panel{
            margin-top: 12px;
            min-width: 164px;
            border-radius: $borderRadiusM;
            box-shadow: $shadowOverlay;

            .p-dropdown-header{
                border-top-right-radius: $borderRadiusM;
                border-top-left-radius: $borderRadiusM;
            }

            .p-dropdown-items .p-dropdown-item-group{
                background: none;

                .country-item img.flag {
                    width: 18px;
                    height: 12px;
                    margin-right: .5rem;
                    border-radius: 3px;
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .layout-topbar {
        justify-content: space-between;

        .layout-topbar-logo {
            width: auto;
            order: 1;
        }

        .layout-menu-button {
            margin-left: 0;
            order: 2;
        }

        //.layout-topbar-menu-button {
        //    display: inline-flex;
        //    margin-left: 0;
        //    order: 3;
        //}
        //
        .layout-topbar-menu {
            order: 3;
        //    margin-left: 0;
        //    position: absolute;
        //    flex-direction: column;
        //    background-color: var(--surface-overlay);
        //    box-shadow: 0px 3px 5px rgba(0,0,0,.02), 0px 0px 2px rgba(0,0,0,.05), 0px 1px 4px rgba(0,0,0,.08);
        //    border-radius: 12px;
        //    padding: 1rem;
        //    right: 2rem;
        //    top: 5rem;
        //    min-width: 15rem;
        //    display: none;
        //    -webkit-animation: scalein 0.15s linear;
        //    animation: scalein 0.15s linear;
        //
        //    &.layout-topbar-menu-mobile-active {
        //        display: block
        //    }
        //
        //    .layout-topbar-button {
        //        margin-left: 0;
        //        display: flex;
        //        width: 100%;
        //        height: auto;
        //        justify-content: flex-start;
        //        border-radius: 12px;
        //        padding: 1rem;
        //
        //        i {
        //            font-size: 1rem;
        //            margin-right: .5rem;
        //        }
        //
        //        span {
        //            font-weight: medium;
        //            display: block;
        //        }
        //    }
        }
    }
}
